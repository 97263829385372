@import 'bootstrap';

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600;700&display=swap');

/* Icons - Font Awesome */
@import './assets/fontawesome/css/fontawesome.css';
@import './assets/fontawesome/css/brands.css';
@import './assets/fontawesome/css/solid.css';
@import './assets/fontawesome/css/regular.css';

/* Pannellum */
@import "pannellum/src/css/pannellum.css";


body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: #FCF7EC;
    font-size: 16px;
    width: 100%;
}

h2 {
    color: #4D433F;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
}

h3 {
    color: #4D433F;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 600;
}

h4 {
    color: white;
    background-color: #FAA338;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
    padding: 5px;
    border-radius: 6px;
    font-size: 24px;
}

p {
    color: #585552;
}

a {
    cursor: pointer;
}
